@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif, Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

/* Chatbot container styles */
.chatbot-container {

  display: flex;
  align-items: center;
}

.smooth-transition {
  transition: all 1s ease;
  /* You can adjust the duration (0.3s) and easing function (ease) as needed */
}


/* Define the bounce animation using keyframes */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

/* Apply the "bounce" animation when the element has the "bounce" class */
.bounce {
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}




@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
    /* Initial position */
  }

  to {
    opacity: 1;
    transform: translateX(0);
    /* Final position */
  }
}

.visible {
  animation: fadeIn 0.5s ease;
  /* Animation duration and timing function */
}





.prev-btn,
.next-btn {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  z-index: 2;
}

.indicators .indicator {
  transition: background-color 0.3s ease-in-out;
}

/* styles.css (or your preferred CSS file) */
.truncat {
  max-width: 25vw;
  /* Set your desired max-width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.test-p {
  width: 100%;
  /* Set the width of the div to 100% of its parent */
  overflow: hidden;
  /* Hide the overflowing content */
  white-space: nowrap;
  /* Prevent text wrapping */
  text-overflow: ellipsis;
}








.price-slider {
  position: relative;
}

.price-label {
  position: absolute;
  top: -25px;
  /* Adjust as needed */
  transform: translateX(-50%);
  /* Additional styling for the label */
}

.slider {
  width: 100%;
  /* Additional styling for the slider */
}








@layer components {

  /* SideBar */
  /* .sidebar {
    @apply flex flex-col h-full rounded-e-2xl p-2 pt-4 relative duration-300 border-slate-600;
  } */
  .sidebar-fixed {
    position: fixed;
    /* Positionnement fixe sur le côté */
    top: 0;
    /* Aligné en haut */
    left: 0;
    /* Aligné à gauche */
    /* height: 100vh; */
    /* Prend toute la hauteur de la vue */
    width: 250px;
    z-index: 1000;
    /* Largeur fixe ouverte */
    transition: width 0.3s ease;
    /* Transition fluide de la largeur */
  }

  .sidebar-open {
    transform: translateX(0);
    /* Position normale */
  }

  .sidebar-closed {
    /* transform: translateX(-100%); */
    /* Déplacé à gauche de 100% de sa propre largeur */
  }

  .sidebar {
    position: fixed;
    /* Assure que la sidebar reste fixée à gauche */
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    /* Largeur fixe que les modifications internes ne peuvent pas altérer */
    z-index: 1000;
    /* Assure qu'elle est au-dessus des autres éléments */
    transition: transform 0.3s ease;
    /* Transition pour transformer et non pour la largeur */
  }

  .sidebar__app-bar {
    @apply flex gap-2;
  }

  .sidebar__app-title {
    @apply text-white font-medium text-xl duration-300;
  }

  .sidebar__btn-close {
    @apply cursor-pointer duration-300 text-white;
  }

  .sidebar__btn-icon {
    @apply w-8 h-8;
  }

  .sidebar__app-logo {
    @apply cursor-pointer duration-300 text-white;
  }

  /* SideBar Nav */
  .nav {
    @apply flex justify-start w-full;
  }

  .nav__item {
    @apply px-4 py-2 flex items-center gap-x-4 w-full rounded-md cursor-pointer hover:bg-blue-100 text-[#06094F] text-base;
  }

  #nav__context {
    @apply overflow-x-hidden h-5/6 space-y-4 overflow-y-auto
  }

  .nav__context {
    @apply flex flex-col border-neutral-600
  }

  .nav__context h1 {
    @apply text-lg font-bold
  }


  .nav__context h2 {
    @apply font-bold mb-2
  }

  .nav__context p {
    @apply text-sm
  }

  .nav__context input {
    @apply rounded-lg p-2
  }

  .nav__icons {
    @apply cursor-pointer duration-300 text-white text-xl items-center;
  }

  .nav__bottom {
    @apply flex justify-end items-stretch;
  }

  .nav__msg {
    @apply flex bg-yellow-900 rounded-xl p-4 text-white;
  }

  .nav__p {
    @apply font-mono;
  }

  #context_elements {
    @apply text-xs;
  }

  /* RightPanel */
  .right-panel-liens-utiles {
    @apply p-2;
  }

  .list-links {
    @apply flex flex-col w-full;
  }

  .link {
    @apply flex items-center justify-start text-[#06094F] text-sm w-full no-underline;
    animation: fadeIn 0.5s ease;
    /* Animation duration and timing function */
  }

  .link:hover {
    @apply underline;
  }

  .right-panel-list-experts {
    @apply flex flex-col w-full;
  }

  .right-panel-expert {
    @apply flex flex-col;
  }

  .right-panel-expert-top {
    @apply w-[100%] flex items-start justify-start;
  }

  /* Pour la première cellule */
  .first-cell {
    border-top-left-radius: 0.5rem;
    /* border-bottom-left-radius: 0.5rem; */
    border-left: 1px solid #4D9FF5;
    border-top: 1px solid #4D9FF5;
    /* border-bottom: 1px solid #4D9FF5; */
  }

  /* Pour les cellules du milieu */
  .middle-cell {
    border-top: 1px solid #4D9FF5;
    /* border-bottom: 1px solid #4D9FF5; */
  }

  /* Pour la dernière cellule */
  .last-cell {
    border-top-right-radius: 0.5rem;
    /* border-bottom-right-radius: 0.5rem; */
    border-right: 1px solid #4D9FF5;
    border-top: 1px solid #4D9FF5;
    /* border-bottom: 1px solid #4D9FF5; */
  }

  .first-cell-tr {
    border-left: 1px solid #4D9FF5;
    border-right: 1px solid #4D9FF5;
  }

  .bottom-cell-tr {
    border-left: 1px solid #4D9FF5;
    /* border-right: 1px solid #4D9FF5; */
    border-bottom: 1px solid #4D9FF5;
    /* border-bottom-right-radius: 0.5rem; */
    /* border-bottom-left-radius: 0.5rem; */

  }

  .left-right-cell-tr {
    border-left: 1px solid #4D9FF5;
    border-right: 1px solid #4D9FF5;
  }

  .bottom-right-cell-tr {
    border-right: 1px solid #4D9FF5;
    border-bottom: 1px solid #4D9FF5;
  }

  .left-cell-tr {
    border-left: 1px solid #4D9FF5;
  }

  .right-cell-tr {
    border-right: 1px solid #4D9FF5;
  }

  .padded-cell {
    padding-top: 10px;
    /* Ajustez la valeur selon vos besoins */
    padding-bottom: 10px;
    /* Ajustez la valeur selon vos besoins */
  }

  .table-row {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    /* Permet d'appliquer les bordures arrondies aux enfants */
    border: 1px solid #4D9FF5;
    margin-bottom: 10px;
    /* Espace entre les lignes */
  }

  .table-cell {
    padding: 10px 20px;
    /* Ajustez le padding pour mieux correspondre à votre capture d'écran */
    background-color: #ffffff;
    /* Fond blanc pour chaque cellule */
    border-right: 1px solid #4D9FF5;
    /* Bordure droite pour séparer les cellules */
  }

  .table-cell:last-child {
    border-right: none;
    /* Supprime la bordure droite pour la dernière cellule */
  }


  table {
    border-collapse: separate;
    border-spacing: 0;
    /* Ajustez au besoin pour l'espacement entre les cellules */
  }

  .expertises {
    @apply truncate;
  }

  .expertises:hover {
    @apply flex-wrap;
    white-space: normal;
    overflow: visible;
  }

  .icon-inside-input {
    margin-right: -20px;
  }





  .bg-first-oliver {
    @apply bg-first-oliver-color
  }

  .bg-second-oliver {
    @apply bg-second-oliver-color
  }

  .text-first-oliver {
    @apply text-first-oliver-color
  }

  .text-second-oliver {
    @apply text-second-oliver-color
  }

  .bg-first-infogreffe {
    @apply bg-first-infogreffe-color
  }

  .bg-second-infogreffe {
    @apply bg-second-infogreffe-color
  }

  .text-first-infogreffe {
    @apply text-first-infogreffe-color
  }

  .text-second-infogreffe {
    @apply text-second-infogreffe-color
  }







  .input:focus {
    outline: none !important;
    border: 3px solid #06094F;
  }



  #icon {
    @apply w-[10%] h-[34px] mr-2;
  }

  /* CHATVIEW */
  .chatview {
    @apply flex flex-col h-full duration-300 overflow-hidden relative bg-slate-200 w-max dark:bg-light-grey;
  }

  .chatview__chatarea {
    @apply flex-grow justify-between w-full flex flex-col overflow-y-auto;

  }

  .chatview__chatarea::-webkit-scrollbar {
    width: 0;
  }

  .form {
    @apply flex justify-between flex-col items-stretch sm:flex-row m-2 bottom-0 left-0 right-0 text-black dark:text-white duration-300;
  }

  .dropdown {
    @apply dark:bg-light-grey h-16 px-3 rounded-xl bg-light-white hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400 dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition ease-in-out duration-300 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 w-[512px] duration-300 ease-out;
  }

  .chatview__textarea-message {
    @apply bg-white ml-0 sm:ml-2 dark:bg-light-grey text-xl h-16 sm:w-full grow max-h-[20rem] min-h-[4rem] px-2 border-2 border-slate-400 dark:border-slate-500 overflow-y-hidden mx-2 focus:ring-offset-indigo-200 focus:ring-2 focus:ring-offset-2 resize-y rounded-xl;
  }

  .chatview__btn-send {
    @apply h-16 w-16 px-4 rounded-full hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400 dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition ease-in-out duration-300 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .chatview__btn-send:disabled {
    @apply opacity-40 transition ease-in duration-300 text-center text-base font-semibold shadow-md;
  }

  /* ChatMessage */
  .message {
    @apply py-3 gap-2 flex justify-end;
    @apply w-full;
    /* max-width: 640px;  sm:min-w-[320px] md:min-w-[480px] lg:min-w-[640px] */
    /* transition: none; */
  }

  .message__wrapper {
    @apply flex-grow flex-wrap w-max;
  }

  .message__markdown {
    @apply text-lg text-[#06094F] dark:text-slate-200;
  }

  .message__createdAt {
    @apply text-xs font-thin text-[#BFBFBF] dark:text-slate-300;
  }

  .system_message__pic {
    @apply h-8 w-8 mx-2 flex items-center justify-center text-xl;
    transition: transform 10s ease-in-out;
  }

  .user_message__pic {
    @apply h-8 w-8 bg-slate-200 mx-2 rounded-full flex items-center justify-center text-xl;
    /* transition: transform 10s ease-in-out; */
  }



  .message__thinking {
    @apply animate-pulse duration-1000 h-12 dark:text-white text-black w-full;
  }

  /* filters buttons */

  .filter_button_suivre_formalite {
    @apply font-medium border border-2 border-[#7FF9D8] text-[#06094F] px-2 py-3 hover:bg-[#7FF9D8] hover:border-[#7FF9D8] rounded-bl-lg rounded-tr-lg rounded-br-lg
  }

  .filter_button_checkup_entreprise {
    @apply font-medium border border-2 border-orange-logo-color text-orange-logo-color rounded px-2 py-1 hover:bg-orange-logo-color hover:text-white
  }

  .filter_button_decision_justice {
    @apply font-medium border border-2 border-[#6FAD9E] text-[#6FAD9E] rounded px-2 py-1 hover:bg-[#6FAD9E] hover:text-white
  }

  .filter_button_demarche_administrative {
    @apply font-medium border border-2 border-[#955972] text-[#955972] rounded px-2 py-1 hover:bg-[#955972] hover:text-white
  }

  .filter_button_reglementation_francaise {
    @apply font-medium border border-2 border-[#CAB98B] text-[#CAB98B] rounded px-2 py-1 hover:bg-[#CAB98B] hover:text-white
  }

  .filter_button_service_infogreffe {
    @apply font-medium border border-2 border-[#6B85B6] text-[#6B85B6] rounded px-2 py-1 hover:bg-[#6B85B6] hover:text-white
  }

  /* Signin Page */


  .loginPage {
    @apply flex w-full h-full bg-white;
  }

  .loginInfosContainer {
    @apply hidden md:flex flex-col w-[70%] bg-white justify-between;
  }

  .loginWords {
    @apply h-[54.5%] flex flex-col justify-center items-center space-y-10 text-4xl text-white font-extralight relative;
  }

  .loginWordsLogo {
    @apply absolute left-8 top-8 w-20;
  }

  .loginWordsW1 {
    @apply w-full text-center mr-[40%];
  }

  .loginWordsW3 {
    @apply w-full text-center ml-[40%];
  }

  .loginInfos {
    @apply bg-zinc-500 flex flex-col justify-center px-20 h-[45%] text-white text-sm lg:text-base font-light space-y-4 justify-center;
  }


  .loginContainer {
    @apply justify-between h-full w-full md:w-[40%] flex flex-col justify-center items-center;
  }


  .loginContainerLogo {
    @apply w-60 pt-8;
  }

  .loginContainerConnexion {
    @apply flex flex-col items-center w-full mt-10;
  }

  .loginContainerConnexion>p {
    @apply text-center text-slate-500 text-xl font-semibold mb-8;
  }

  .loginContainerFooter {
    @apply flex text-xs w-full md:w-[99.5%] h-14 justify-around p-4 items-center text-white;
  }

  .loginButtonWrapper {
    @apply flex justify-around space-x-6 text-white font-semibold w-full;
  }

  .loginButtonWrapper button {
    @apply bg-[#06094F] p-2 rounded-lg py-3 px-8;
  }

  .signupFormContainer {
    @apply flex flex-col items-center w-full;
  }

  .signupFormContainer .text-input {
    @apply bg-white my-2 border-slate-400 border-2 rounded-lg w-full
  }

  .signupFormContainerForm {
    @apply flex flex-col items-center;
  }

  .signupFormContainerForm button {
    @apply bg-[#06094F] p-2 rounded-lg py-2 px-8 text-white my-4;
  }

  .signupFormContainerContinuer {
    @apply bg-[#06094F] p-2 rounded-lg py-2 px-8;
  }

  .signupFormContainerRetour {
    @apply text-sm font-semibold;
  }

  .forgotPasswordButton {
    @apply text-sm font-semibold;
  }


  .loginContainerContent .text-input::placeholder {
    @apply p-2;
  }


  .loginContainerContent .text-input[value] {
    @apply text-black p-2;
  }


  .signupWithGoggle {
    @apply flex flex-row items-center space-x-2 text-slate-500 font-semibold my-4 mt-6 cursor-pointer;
  }

  #signupPassword {
    @apply flex items-center relative flex flex-col w-full;
  }

  .error_message {
    @apply text-red-600;
  }

  .cgus h5 {
    @apply text-2xl font-semibold my-4;
  }

  .cgus img {
    @apply w-[30%]
  }




  .article {
    @apply text-blue-500 cursor-pointer hover:underline;
  }

  .modal {
    @apply w-full;
  }

  /* adminpanel */
  .adminPanelContainer {
    @apply flex flex-col space-y-4
  }

  .adminPanelOnglets>button {
    @apply p-1 rounded-md cursor-pointer text-base;
  }

  .adminPanelOnglets {
    @apply flex space-x-4;
  }

  .ficheRenforcementForm {
    @apply flex flex-col space-y-4 items-center;
  }

  .ficheRenforcementForm>div {
    @apply w-full;
  }

  .italic-placeholder::placeholder {
    font-style: italic;
    font-size: 0.9rem;
  }




}

::-webkit-scrollbar {
  @apply bg-transparent w-1;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-grey dark:bg-dark-grey rounded-xl;
}

.w-max {
  width: 100%;
}

/* CSS pour masquer les boutons supplémentaires sur les petits écrans */
@media (max-width: 359px) {
  .hide-on-small {
    display: none;
  }
}

@media (max-width: 411px) {
  .hide-suggestion-on-small {
    display: none;
  }
}

@media (max-height: 599px) {
  .hide-on-small-height {
    display: none;
  }
}

@media (max-height: 539px) {
  .hide-suggestion-on-small-height {
    display: none;
  }
}

/* CSS pour activer le défilement horizontal sur un conteneur de boutons */
/* .scrollable-container {
  overflow-x: auto;
  display: flex;
  gap: 4px;
} */